<script setup>
import useUser from '@/store/user';
import StaticPage from './home/StaticPage.vue'
import LastProductsPage from './home/LastProductsPage.vue';


const userStore = useUser();
</script>

<template>
    <StaticPage v-if="userStore.user == null"/>
    <LastProductsPage v-else />
</template>