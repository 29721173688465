<script setup>
import { ref } from 'vue';
import swal from '@/swal';
import { useRouter } from 'vue-router';
import ImageInput from '@/components/inputs/ImageInput.vue';
import Axios from '@/axios';
import axios from 'axios';
import { baseURL } from '@/axios';
// import {createCategory} from "@/requests/categories" 

const router = useRouter();
const uploaded = ref(0);
const categoryData = ref({
  name: null,
  image: null
});

const categoryValidation = ref({
  name: null,
  image: null
});

const submitCreateCategory = async () => {
  try {
    // let response = await createCategory(categoryData.value);
    let response;

    await axios.get(`${baseURL}/sanctum/csrf-cookie`).then(async() => {
      response = await Axios.post("/admin/categories/create", categoryData.value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (e) => {
          uploaded.value = Math.floor((e.loaded * 100) / e.total);
        }
      });
    });

    uploaded.value = 0;

    if (response.data.status == "success") {
      swal("success", `دسته ${categoryData.value.name} ایجاد شد.`);
      router.replace({ name: "admin.categories.index" });
    }
  } catch (error) {
    uploaded.value = 0;
    if (error.response && error.response.status == 422) {
      categoryValidation.value = error.response.data.errors;
    } else {
      console.log(error.response);
    }
  }
}

</script>

<template>
  <div class="min-w-full flex flex-col justify-start items-start bg-transparent rounded-lg p-4 mb-5">
    <div class="w-full flex flex-row justify-between items-center mb-5">
      <span class="font-bold text-gray-600">ایجاد دسته جدید</span>
      <RouterLink :to="{ name: 'admin.categories.index' }"><i
          class="bi bi-arrow-left bg-gray-500 px-2 py-1 text-white text-xl text-center rounded-full"></i></RouterLink>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="categoryData.name" id="name" class="input-control"
          :class="{ 'outline-red-500': categoryValidation.name }" type="text" placeholder=" " />
        <label for="name" class="label-control">نام دسته بندی</label>
      </div>
      <span v-if="categoryValidation.name" class="invalid">{{
        categoryValidation.name[0]
      }}</span>
    </div>
    <div class="mb-4 w-full">
      <ImageInput :onChange="(event) => (categoryData.image = event.target.files[0])" :error="categoryValidation.image" />
    </div>

    <div class="flex flex-row w-full justify-end">
      <button @click="submitCreateCategory" class="px-4 py-2 bg-white rounded-md text-gray-500">
        ایجاد دسته بندی
      </button>
      <span>{{ uploaded }}%</span>
    </div>
  </div>
</template>
