<script setup>
import Axios from '@/axios';
import useUser from '@/store/user';
import swal from '@/swal';
import { computed, defineProps, ref, watch } from 'vue';
import ProductCartEditComponent from './ProductCartEditComponent.vue'

const Props = defineProps({
    product: {
        required: true,
        type: Object
    },
    setImageModalUrl: {
        required: true,
        type: Function
    }
});

const quantity = ref(Props.product.inRoster == null
    ? 0
    : (Props.product.inRoster.status == 1 ? 0 : Props.product.inRoster.quantity));

const loading = ref(false);
const userStore = useUser();
const shownEditSlide = ref(false);



const decrementQuantityAsCarton = () => {
    if (quantity.value > 0) {
        if (quantity.value < Props.product.carton_contains) {
            quantity.value = 0;
        } else {
            quantity.value -= Props.product.carton_contains;
        }

    }
}

const incrementQuantityAsCarton = () => {
    if (quantity.value < Props.product.quantity) {
        if (quantity.value + Props.product.carton_contains > Props.product.quantity) {
            quantity.value = Props.product.quantity;
        } else {
            quantity.value += Props.product.carton_contains;
        }
    }
}

const decrementQuantity = () => {
    if (quantity.value > 0) {
        quantity.value -= 1;

    }
}

const incrementQuantity = () => {
    if (quantity.value < Props.product.quantity) {
        quantity.value += 1;
    }
}

const saveToRoster = async () => {
    try {
        loading.value = true;
        await Axios.post('/roster', { product_id: Props.product.id, quantity: quantity.value });
        loading.value = false;
    } catch (error) {
        loading.value = false;
        swal('error', 'خطا در برقراری ارتباط با سرور.')
    }
}

const disableSaveToRoster = computed(() => {
    // pending in roster
    if (Props.product.inRoster && Props.product.inRoster.status == 0) {
        if (quantity.value == Props.product.inRoster.quantity) {
            return true;
        }
    // completed in roster
    } else if (Props.product.inRoster && Props.product.inRoster.status == 1) {
        if (quantity.value < 1) {
            return true;
        }
    // dos not add to roster and quantity = 0
    }else if (!Props.product.inRoster && quantity.value < 1){
        return true;
    }
    return false;
})

// watchers
watch(() => Props.product.quantity, () => {
    if (quantity.value > Props.product.quantity) {
        quantity.value = Props.product.quantity;
    }
});

</script>

<template>
    <div class="basis-full lg:basis-1/2 p-2">
        <div class="bg-white p-2 rounded-md flex-flex-col relative">
            <div class="flex flex-row items-center mb-2">
                <img @click="Props.setImageModalUrl(`${Props.product.image}&width=400&height=400`)"
                    :src="`${Props.product.image}&width=50&height=50`" :alt="Props.product.name" width="50" height="50" />
                <div class="flex flex-col mx-2 space-y-2">
                    <span class="text-gray-800 text-sm font-bold">{{ Props.product.name }}</span>
                    <span class="text-gray-400 font-bold text-xs">دسته بندی:<span class="text-red-500">{{
                        Props.product.category.name }}</span></span>
                    <span class="text-gray-400 font-bold text-xs">موجودی‌: تعداد <span class="text-red-900 mx-1">{{
                        Props.product.quantity }} </span>عدد در قالب کارتن های<span class="text-red-900 mx-1">{{
        Props.product.carton_contains }} </span> عددی</span>
                </div>
            </div>
            <div class="flex flex-row items-start">
                <div class="basis-8/12 flex flex-col justify-center">
                    <div class="flex flex-row items-center">
                        <button @click="incrementQuantity" class="relative p-2 bg-palette1-orange-1 rounded-md me-2">
                            <i class="bi bi-caret-up-fill text-2xl"></i>
                        </button>
                        <button @click="incrementQuantityAsCarton"
                            class="relative p-2 bg-palette1-orange-1 rounded-md me-2">
                            <i class="bi bi-dropbox text-2xl"></i>
                            <i class="bi bi-caret-up-fill text-lg absolute top-[-5px] right-0 "></i>
                        </button>

                        <div class="p-2 bg-sky-200 rounded-md">{{ quantity }}</div>


                        <button @click="decrementQuantityAsCarton"
                            class="relative p-2 bg-palette1-orange-1 rounded-md ms-2">
                            <i class="bi bi-dropbox text-2xl"></i>
                            <i class="bi bi-caret-down-fill text-xl absolute bottom-[-5px] left-0"></i>
                        </button>

                        <button @click="decrementQuantity" class="relative p-2 bg-palette1-orange-1 rounded-md ms-2">
                            <i class="bi bi-caret-down-fill text-2xl"></i>
                        </button>
                    </div>
                    <div v-if="Props.product.inRoster">
                        <span v-if="Props.product.inRoster.status == 0" class="font-bold text-xs mt-2 text-red-600">تعداد {{
                            Props.product.inRoster.quantity }} عدد توسط {{ Props.product.inRoster.user.id ==
        userStore.user.id ? 'شما' : Props.product.inRoster.user.name }} در لیست ثبت
                            شده.</span>
                        <span v-else class="font-bold text-xs mt-2 text-green-600">مقدار {{ Props.product.inRoster.quantity
                        }} عدد بارگیری شد.</span>
                    </div>


                </div>
                <div class="basis-4/12 flex justify-end"><button @click="saveToRoster"
                        :disabled="disableSaveToRoster || loading"
                        class="mx-2 py-2 px-4 bg-palette1-green-2 disabled:bg-palette1-green-1 disabled:text-gray-600 rounded-md text-white text-sm">
                        <div v-if="loading" class="loading-dots px-6 py-2">
                            <div class="loading-dot bg-white"></div>
                            <div class="loading-dot bg-white"></div>
                            <div class="loading-dot bg-white"></div>
                        </div><span v-else>{{
                            (quantity == 0 && Props.product.inRoster && Props.product.inRoster.status == 0 &&
                                Props.product.inRoster.quantity > 0) ? 'حدف از لیست'
                            :
                            'افزودن به لیست' }}</span>
                    </button>
                </div>
                <button v-show="userStore.user && (userStore.role == 'Super Admin' || userStore.role == 'Store Keeper')" @click="shownEditSlide = true" class="absolute py-1 px-2 bg-gray-500 rounded-md top-0 left-0 m-2 text-white">
                    <i class="bi bi-gear text"></i>
                </button>
                <Transition enter-active-class="transition-all duration-300 origin-left"
                    leave-active-class="transition-all duration-300 origin-left" enter-from-class="scale-x-0"
                    enter-to-class="scale-x-100" leave-from-class="scale-x-100" leave-to-class="scale-x-0">
                    <ProductCartEditComponent v-if="shownEditSlide" :product="Props.product"
                        :close="() => shownEditSlide = false" />

                </Transition>
            </div>

        </div>

    </div>
</template>