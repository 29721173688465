<script setup>
import {ref,defineProps, watchEffect } from "vue";

const Props = defineProps({
  onChange: Function,
  error:Array,
  image:String
});

const image = ref(null);
const tempImage = ref (null);
const imageName = ref(null);

const changeInput = (event)=>{
    tempImage.value = URL.createObjectURL(event.target.files[0]);
    imageName.value = event.target.files[0].name
    Props.onChange(event);
}

watchEffect(()=>image.value = Props.image);
</script>

<template>
  <div class="bg-transparent flex flex-row-reverse items-end space-x-2">
    <input
      @change="changeInput"
      id="image"
      class="w-[0.1px] h-[0.1px] opacity-0 overflow-hidden absolute z-[-1]"
      :class="{ 'outline-red-500': Props.error }"
      type="file"
      placeholder=" "
    />
    <img class="basis-1/2 w-[50%]" v-if="tempImage" :src="tempImage" alt="">
    <img class="basis-1/2 w-[50%]" v-else-if="image" :src="image" alt="">
    <label
      for="image"
      class="basis-1/2 flex flex-row justify-between items-center bg-palette1-orange-3 p-2 rounded-md text-white"
    >
        <span v-text="imageName || 'اتنخاب تصویر'"></span>
      <i class="bi bi-upload text-xl mx-2"></i>
    </label>
  </div>
  
  <span v-if="Props.error" class="invalid">{{
    Props.error[0]
  }}</span>
</template>
