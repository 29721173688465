<script setup>
import { defineProps, ref, watch } from 'vue';
import Axios from '@/axios';
import swal from '@/swal';

const Props = defineProps({
    product: {
        required: true,
        type: Object
    },
    close: {
        required: true,
        type: Function
    }
});

const quantityForEdit = ref(Props.product.quantity);
const cartonOfQuantity = ref(Math.floor(quantityForEdit.value / Props.product.carton_contains));
const microOfQuantity = ref(quantityForEdit.value % Props.product.carton_contains);
const loading = ref(false);

const submitUpdate = async () => {
    try {
        loading.value = true;
        await Axios.patch(`admin/products/${Props.product.id}`, {
            name: Props.product.name,
            store: Props.product.store.id,
            category: Props.product.category.id,
            carton_contains: Props.product.carton_contains,
            quantity: parseInt(quantityForEdit.value),
        });
        loading.value = false

    } catch (error) {
        swal('error', 'مشکل در برقراری ارتباط با سرور.');
        console.log(error);
    }

}

watch(quantityForEdit, () => {
    if (quantityForEdit.value < 0 || typeof (quantityForEdit.value) != 'number') {
        quantityForEdit.value = 0;
    } else {
        quantityForEdit.value = Math.floor(quantityForEdit.value);
    }
    cartonOfQuantity.value = Math.floor(quantityForEdit.value / Props.product.carton_contains);
    microOfQuantity.value = quantityForEdit.value % Props.product.carton_contains;
});
watch(cartonOfQuantity, () => {
    if (cartonOfQuantity.value < 0 || typeof (cartonOfQuantity.value) != 'number') {
        cartonOfQuantity.value = 0;
    } else {
        cartonOfQuantity.value = Math.floor(cartonOfQuantity.value);
    }
    quantityForEdit.value = cartonOfQuantity.value * Props.product.carton_contains + microOfQuantity.value
});
watch(microOfQuantity, () => {
    if (microOfQuantity.value < 0 || typeof (microOfQuantity.value) != 'number') {
        microOfQuantity.value = 0;
    } else {
        microOfQuantity.value = Math.floor(microOfQuantity.value);
    }
    quantityForEdit.value = cartonOfQuantity.value * Props.product.carton_contains + microOfQuantity.value
});
</script>

<template>
    <div class="bg-palette1-gray-4 w-full h-full absolute top-0 right-0 bg-opacity-80 flex flex-col">
        <div class="mt-2 mb-2 w-[90%] flex flex-row flex-wrap px-2">
            <div class="basis-1/2 px-2">
                <div class="form-control bg-white">
                    <input v-model="cartonOfQuantity" id="quantity" class="input-control p-1" type="number" placeholder=" "
                        min="0" />
                    <label for="quantity" class="label-control">تعداد کارتن</label>
                </div>
            </div>
            <div class="basis-1/2 px-2">
                <div class="form-control bg-white">
                    <input v-model="microOfQuantity" id="quantity" class="input-control p-1" type="number" placeholder=" "
                        min="0" />
                    <label for="quantity" class="label-control">تعداد جز‌‌‌‌ء</label>
                </div>
            </div>
        </div>
        <div class="w-[90%] flex flex-row flex-wrap px-2 items-center">
            <div class="basis-1/2 px-2">
                <div class="form-control bg-white">
                    <input v-model="quantityForEdit" id="quantity" class="input-control p-1" type="number" placeholder=" "
                        min="0" />
                    <label for="quantity" class="label-control">تعداد کل</label>
                </div>
            </div>
            <div class="basis-1/2 px-2 flex justify-end ">
                <button @click="submitUpdate"
                    class="flex flex-row items-center py-1 px-4 bg-blue-700 disabled:bg-blue-900 disabled:text-gray-400 text-white rounded-md justify-end"
                    :disabled="loading">
                    <span>ثبت</span>
                    <i v-if="!loading" class="bi bi-upload mx-2"></i>
                    <div v-else
                        class="mx-2 animate-spin p-2 bg-blue-900  w-3 h-3 !border-4 !border-gray-400  !border-b-white rounded-full top-1/2 right-1/2">
                    </div>
                </button>
            </div>
        </div>
        <button @click="Props.close"
            class="absolute left-0 py-1 px-2 m-1 bg-gray-600 rounded-md font-bold text-white">X</button>
</div></template>