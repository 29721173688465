<script setup>
import { ref, defineProps } from "vue";
import useStore from '@/store/stores';
import useUser from '@/store/user';
import swal from "@/swal";
import { deleteStoreRequest } from "@/requests/storeRequest";

const destroyLoading = ref(false);
const storeStore = useStore();
const userStore = useUser();
const Props = defineProps({
  store: {
    type: Object,
    required: true,
  },
});

const destroyCategory = async () => {
  try {
    destroyLoading.value = true;
    let response = await deleteStoreRequest(Props.store.id);
    if (response.data.status == "success") {
      storeStore.removeStore(Props.store.id);
      destroyLoading.value = false;
      swal('success', `انبار ${Props.store.name} با موفقیت حذف شد.`);
    }
  } catch (error) {
    console.log(error);
  }
};
</script>

<template>
  <tr class="even:bg-slate-50">
    <td>
      <div class="w-full flex flex-row items-center px-2">
        <span class="font-medium text-gray-800 dark:text-white ms-2">
          {{ Props.store.name }}
        </span>
      </div>
    </td>
    <td>
      <div class="flex flex-row justify-end items-center space-x-4 px-4">
        <div class="py-2">
        <i v-if="destroyLoading"
          class="bi bi-gear-fill text-xl text-gray-700 py-1 px-2 m-2 bg-gray-300 rounded-md cursor-pointer"></i>
        <RouterLink v-else :to="{ name: 'admin.stores.edit', params: { id: store.id } }">
          <i class="bi bi-gear-fill text-xl text-gray-700 py-1 px-2 m-2 bg-gray-300 rounded-md cursor-pointer"></i>
        </RouterLink>
      </div>
        <div class="py-2" v-if="userStore.role == 'Super Admin'">
          <i v-if="!destroyLoading" @click="destroyCategory"
            class="bi bi-trash-fill text-xl text-red-500 py-1 px-2 m-2 bg-gray-300 rounded-md cursor-pointer"></i>
          <div v-else class="p-1 m-2 bg-gray-300 rounded-md">
            <div
              class=" animate-spin p-2 bg-gray-300  w-6 h-6 !border-4  !border-b-blue-500 rounded-full top-1/2 right-1/2">
            </div>
          </div>
        </div>

      </div>
    </td>
  </tr>
</template>
