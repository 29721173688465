<script setup>
import useHomeProducts from '@/store/homeProducts'
import ProductCartComponent from "@/components/home/ProductCartComponent"
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import useCategories from '@/store/categories';
import echo from '@/echo';


const homeProductsStore = useHomeProducts();
const categoriesStore = useCategories();
const searchDispatch = ref(true);
const nextPageLoading = ref(false);
const { search, categories: categoriesForFilter, products } = storeToRefs(homeProductsStore);
const filtersSidebarShown = ref(false);
let searchTimeout = null;

const updateSearchValue = (e) => {
    searchDispatch.value = false
    if (searchTimeout) {
        clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
        homeProductsStore.setSearchValue(e.target.value);
        searchDispatch.value = true;
    }, 700)
}

const loadNextPage = async () => {
    nextPageLoading.value = true;
    await homeProductsStore.fetchData(homeProductsStore.current_page + 1);
    nextPageLoading.value = false;
}


const checkBoxClick = (id) => {
    if (categoriesForFilter.value.includes(id)) {
        categoriesForFilter.value = categoriesForFilter.value.filter(row => row != id);
    } else {
        categoriesForFilter.value = [...categoriesForFilter.value, id]
    }
}

// image modal
const imageModal = ref(false);
const modalUrl = ref(null);


const showImageModal = (url) => {
    imageModal.value = true,
        modalUrl.value = url;
}

const closeModal = () => {
    imageModal.value = false,
        modalUrl.value = null;
}

// _______________________

// echo listeners
echo.private('products').stopListening('.edit').stopListening('.delete');
echo.private('products').listen('.edit', (e) => {
    homeProductsStore.editItem(e.product);
});
echo.private('products').listen('.delete', (e) => {
    homeProductsStore.removeItem(e.id);
});

if (!homeProductsStore.loading) {
    homeProductsStore.products = [];
    homeProductsStore.current_page = 1;
    homeProductsStore.fetchData();
}
watch(categoriesForFilter, () => {
    homeProductsStore.setCategoriesValue(categoriesForFilter.value);
});

</script>

<template>
    <div v-click-outside="() => { filtersSidebarShown = false }">
        <button @click="() => { filtersSidebarShown = true }"
            class="fixed top-[25vh] left-0 p-2 bg-palette1-green-1 rounded-r-md z-[1001]">
            <i class="bi bi-funnel-fill text-white"></i>
        </button>
        <Transition enter-active-class="transition-all duration-300 origin-left"
            leave-active-class="transition-all duration-300 origin-left" enter-from-class="scale-x-0"
            enter-to-class="scale-x-100" leave-from-class="scale-x-100" leave-to-class="scale-x-0">
            <div v-show="filtersSidebarShown"
                class="fixed p-2 bg-palette1-green-1 text-white min-w-[200px] max-w-[80vw] h-[50vh] top-0 translate-y-[50%] rounded-r-md border-2 border-gray-600 left-0 z-[1010] overflow-y-auto overflow-x-auto">
                <div class="flex flex-row justify-between items-center">
                    <span>دسته بندی :</span>
                    <button @click="() => { filtersSidebarShown = false }">
                        <i class="bi bi-arrow-left text-2xl"></i>
                    </button>
                </div>
                <div class="flex flex-col w-full">
                    <div @click="() => { !homeProductsStore.loading && checkBoxClick(category.id) }" class="form-check"
                        v-for="category in categoriesStore.categories" :key="category.id">
                        <input v-model="categoriesForFilter" :value="category.id" type="checkbox"
                            :id="`category-${category.id}`" :disabled="homeProductsStore.loading">
                        <span class="custom-check"> </span>
                        <label :for="`category-${category.id}`">{{ category.name }}</label>
                    </div>
                </div>
            </div>
        </Transition>
    </div>

    <div class="mt-6 md:flex md:items-center md:justify-between z-[999]">
        <div class="relative flex items-center mt-4 md:mt-0">
            <span class="absolute">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
            </span>
            <span v-if="!searchDispatch" class="absolute top-0 left-0 flex h-3 w-3">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
            </span>
            <input v-model="search" @input="updateSearchValue" type="text" placeholder="جستجو"
                class="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
        </div>
    </div>
    <div v-if="homeProductsStore.products.length < 1" class="flex flex-row justify-center my-4 min-h-[60vh]">
        <span v-if="homeProductsStore.loading" class="font-bold text-sm text-gray-800">در حال دریافت اطلاعات ...</span>
        <span v-else class="font-bold text-sm text-gray-800">هیچ محصولی یافت نشد.</span>
    </div>
    <div v-else class="w-full flex flex-col md:flex-row flex-wrap md:items-center mb-4">

        <ProductCartComponent v-for="product in products" :key="product.id" :product="product"
            :setImageModalUrl="(url) => showImageModal(url)" />
    </div>
    <div v-if="homeProductsStore.next_page != null" class="flex flex-row justify-center mb-12">
        <button @click="loadNextPage" class="bg-white p-2 rounded-xl text-gray-800 text-sm font-bold disabled:bg-gray-400"
            :disabled="nextPageLoading">نمایش موارد بیشتر</button>
    </div>
    <div v-if="imageModal" class="fixed w-[100vw] h-[100vh] bg-black bg-opacity-50 top-0 right-0 z-[1010]">
        <button @click="closeModal"
            class="border-2 border-white rounded-md absolute top-4 right-4 p-2 bg-gray-800 cursor-pointer">
            <i class="bi bi-x-lg text-3xl text-white"></i>
        </button>
        <img :src="modalUrl"
            class="max-w-[400px] max-h-[400px] right-1/2 top-1/2 absolute translate-x-[50%] translate-y-[-50%]" alt="">
    </div>
</template>