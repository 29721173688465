import axios from "axios";

export const baseURL = "https://barzegar-shop.ir";


const Axios = axios.create({
    baseURL: `${baseURL}/v1`,
    headers :{
        Accept : 'application/json'
    },
});
Axios.defaults.withCredentials = true;

export default Axios;
