import { indexRequest } from "@/requests/categories";
import { defineStore } from "pinia";

const useCategories = defineStore("categories", {
  state: () => {
    return {
      categories: null,
    };
  },
  actions: {
    async fetchCategories() {
      let response = await indexRequest();
      this.categories = response.data.data.categories;
    },

    addCategory(category) {
      this.categories = [...this.categories, category];
    },

    editCategory(category) {
      let index = this.categories.findIndex((cate) => cate.id == category.id);
      if (index > -1) {
        this.categories[index] = category;
      }
    },

    removeCategory(id) {
      this.categories = this.categories.filter((category) => category.id != id);
    },
  },
});

export default useCategories;
