import Axios from "@/axios";
import { defineStore } from "pinia";

const useHomeProducts = defineStore("homeProducts", {
  state: () => {
    return {
      products: [],
      links: null,
      meta: null,
      current_page: 1,
      next_page: null,
      last_page: 1,
      categories: [],
      search: "",
      loading: false,
    };
  },
  actions: {
    async fetchData(page = this.current_page) {
      let stringCategories = this.categories.toString();

      try {
        this.loading = true;
        let response = await Axios.get(
          `/products?page=${page}&categories=${stringCategories}&search=${this.search}`
        );
        this.products = [...this.products, ...response.data.data.products];
        this.links = response.data.data.links;
        this.meta = response.data.data.meta;
        this.current_page = response.data.data.meta.current_page;
        this.next_page = response.data.data.links.next;
        this.last_page = response.data.data.meta.last_page;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async removeItem(id) {
      let index = this.products.findIndex((product) => product.id == id);
      if (index > -1) {
        this.products = this.products.filter((product) => product.id != id);

        // get receive next item
        let stringCategories = this.categories.toString();

        let response = await Axios.get(
          `/products?page=${this.current_page}&categories=${stringCategories}&search=${this.search}`
        );
        if (response.data.data.products.length) {
          this.products = [
            ...this.products,
            response.data.data.products[response.data.data.products.length - 1],
          ];
        }

        this.links = response.data.data.links;
        this.meta = response.data.data.meta;
        this.current_page = response.data.data.meta.current_page;
        this.next_page = response.data.data.links.next;
        this.last_page = response.data.data.meta.last_page;
      }
    },
    editItem(product) {
      let index = this.products.findIndex(
        (TempProduct) => TempProduct.id == product.id
      );
      if (index > -1) {
        this.products[index] = product;
      }
    },
    addInRoster(inRoster) {
      let index = this.products.findIndex(
        (TempProduct) => TempProduct.id == inRoster.product_id
      );
      if (index > -1) {
        this.products[index].inRoster = inRoster;
      }
    },
    editInRoster(inRoster) {
      let index = this.products.findIndex(
        (TempProduct) => TempProduct.id == inRoster.product_id
      );
      if (index > -1) {
        this.products[index].inRoster = inRoster;
      }
    },
    removeInRoster(id) {
      let index = this.products.findIndex(
        (TempProduct) => TempProduct.inRoster && TempProduct.inRoster.id == id
      );
      if (index > -1) {
        this.products[index].inRoster = null;
      }
    },
    setSearchValue(value) {
      let homeProductsStore = useHomeProducts();
      (this.search = value), (this.$state.products = []);
      homeProductsStore.fetchData(1);
    },
    setCategoriesValue(value) {
      let homeProductsStore = useHomeProducts();
      this.categories = value;
      this.$state.products = [];
      if (!this.loading) {
        homeProductsStore.fetchData(1);
      }
    },
  },
});

export default useHomeProducts;
