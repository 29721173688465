<script setup>
import { ref } from "vue";
import swal from "@/swal";
import { useRouter, useRoute } from "vue-router";
import ImageInput from "@/components/inputs/ImageInput.vue";
import {updateCategory } from "@/requests/categories";
import useCategories from "@/store/categories";

const router = useRouter();
const route = useRoute();
const categoryStore = useCategories();
const categoryId = route.params.id;

const categoryData = ref({
  name: null,
  image: null,
  imageURL: null,
});

const categoryValidation = ref({
  name: null,
  image: null,
});

const findCategory = () => {
  let find = null;

  if (categoryStore.categories) {
    find = categoryStore.categories.find((category) => category.id == categoryId);
  }

  if (!find) {
    swal("error", `دسته مد نظر یافت نشد.`);
    router.replace({ name: "admin.categories.index" });
  } else {
    categoryData.value.name = find.name
    categoryData.value.imageURL = find.image
  }
}


const submitUpdateCategory = async () => {
  try {
    let response = await updateCategory(categoryId, categoryData.value);

    if (response.data.status == "success") {
      swal("success", `دسته ${categoryData.value.name} ویرایش شد.`);
      router.replace({ name: "admin.categories.index" });
    }
  } catch (error) {
    if (error.response && error.response.status == 422) {
      categoryValidation.value = error.response.data.errors;
    } else {
      console.log(error.response);
    }
  }
};

findCategory();
</script>

<template>
  <div class="min-w-full flex flex-col justify-start items-start bg-transparent rounded-lg p-4 mb-5">
    <div class="w-full flex flex-row justify-between items-center mb-5">
      <span class="font-bold text-gray-600">ویرایش دسته بندی</span>
      <RouterLink :to="{ name: 'admin.categories.index' }"><i
          class="bi bi-arrow-left bg-gray-500 px-2 py-1 text-white text-xl text-center rounded-full"></i></RouterLink>
    </div>
    <div class="w-full">
      <div class="mb-4 w-full">
        <div class="form-control bg-white">
          <input v-model="categoryData.name" id="name" class="input-control"
            :class="{ 'outline-red-500': categoryValidation.name }" type="text" placeholder=" " />
          <label for="name" class="label-control">نام دسته بندی</label>
        </div>
        <span v-if="categoryValidation.name" class="invalid">{{
          categoryValidation.name[0]
        }}</span>
      </div>
      <div class="mb-4 w-full">
        <ImageInput :image="categoryData.imageURL+'&width=400&height=400'" :onChange="(event) => (categoryData.image = event.target.files[0])"
          :error="categoryValidation.image" />
      </div>

      <div class="flex flex-row w-full justify-end">
        <button @click="submitUpdateCategory" class="px-4 py-2 bg-white rounded-md text-gray-500">
          ویرایش دسته بندی
        </button>
      </div>
    </div>
  </div>
</template>
