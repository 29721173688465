<script setup>
</script>

<template>
    <div class="flex flex-col items-center">
        <img class="max-w-[300px]" src="../../assets/logo.png" alt="logo">
        <span class="font-bold text-gray-700 text-xl text-center">برای استفاده از برنامه لطفا ابتدا وارد اکانت کاربری خود شوید.</span>
        <RouterLink :to="{name:'login'}" class="py-2 px-8 text-white my-4 bg-palette1-green-1 rounded-md">ورود</RouterLink>
        <span class="text-sm text-red-800 font-bold text-center mt-24">با به اشتراک گذاشتن مشکلات،انتقاد ها و پیشنهاد هاتون با من در راستای بهبود عملکرد برنامه متشکرم.</span>
        <span class="text-gray-800 text-center text-xs mb-12">این یک برنامه برای مدیریت لیست فروشگاه می‌باشد که توسط <a class="text-red-900 font-bold" href="mailto:rasool.sh344@gmail.com">rasool.sh344@gmail.com</a> طراحی و پیاده سازی شده و استفاده از آن با ذکر نام صاحب اثر بلامانع است.</span>
    </div>
</template>