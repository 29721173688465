import Axios from "@/axios";
import { defineStore } from "pinia";

const useProducts = defineStore("products", {
  state: () => {
    return {
      products: null,
      links: null,
      meta: null,
      current_page: 1,
      last_page: 1,
    };
  },
  actions: {
    async fetchData( filters = {},page=this.current_page) {
      let query = "";
      if (Object.keys(filters).length) {
        query = "&";

        Object.keys(filters).forEach((key, index) => {
          if (index > 0) {
            query = `${query}&`;
          }
          query = `${query}${key}=${filters[key]}`;
        });
      }

      try {
        let response = await Axios.get(
          `admin/products?page=${page}${query}`
        );
        this.products = response.data.data.products;
        this.links = response.data.data.links;
        this.meta = response.data.data.meta;
        this.current_page = response.data.data.meta.current_page;
        this.last_page = response.data.data.meta.last_page;
      } catch (error) {
        console.log(error);
      }
    },
    removeItem(id,filters){
      let index=this.products.findIndex(product=>product.id == id)
      if(index > -1){
        this.products = this.products.filter(product => product.id != id);
        this.fetchData(filters);
      }
    },
    editItem(product){
      let index=this.products.findIndex(TempProduct=>TempProduct.id == product.id)
      if(index > -1){
        this.products[index] = product
      }
    }
  },
});

export default useProducts;
