import { createRouter, createWebHistory } from "vue-router";
import AdminWrapper from "./pages/admin/WrapperPage.vue";
import AdminHomePage from "./pages/admin/HomePage.vue";
import UsersIndex from "./pages/admin/users/IndexPage.vue";
import CategoriesIndex from "./pages/admin/categories/IndexPage.vue";
import StoresIndex from "./pages/admin/stores/IndexPage.vue";
import ProductsIndex from "./pages/admin/products/IndexPage.vue";
import UsersCreate from "./pages/admin/users/CreatePage.vue";
import CategoriesCreate from "./pages/admin/categories/CreatePage.vue";
import StoresCreate from "./pages/admin/stores/CreatePage.vue";
import ProductsCreate from "./pages/admin/products/CreatePage.vue";
import UserEdit from "./pages/admin/users/EditPage.vue";
import CategoriesEdit from "./pages/admin/categories/EditPage.vue";
import StoresEdit from "./pages/admin/stores/EditPage.vue";
import ProductsEdit from "./pages/admin/products/EditPage.vue";
import LoginPage from "./pages/auth/LoginPage";
import useUser from "./store/user";
import HomePage from "@/pages/HomePage";
import CategoriesPage from "@/pages/CategoriesPage";
import RosterPage from "@/pages/RosterPage";
import NotFoundPage from "@/pages/NotFoundPage";
import ServerErrorPage from "@/pages/ServerErrorPage";

const middleware = {
  isGust(to, from) {
    if (useUser().user == null && !localStorage.getItem("user")) {
      return true;
    } else {
      return { path: from.path, query: from.query, hash: from.hash };
    }
  },
  isAuth() {
    if (useUser().user != null && localStorage.getItem("user")) {
      return true;
    } else {
      return { name: "home" };
    }
  },
  isAdmin() {
    if (useUser().user != null && useUser().role == "Super Admin") {
      return true;
    } else {
      return { name: "home" };
    }
  },
  isStoreKeeper() {
    if (useUser().user != null && useUser().role == "Store Keeper") {
      return true;
    } else {
      return { name: "home" };
    }
  },
};

const routes = [
  { path: "/", name: "home", component: HomePage },
  {
    path: "/categories",
    name: "categories",
    component: CategoriesPage,
    beforeEnter: [middleware.isAuth],
  },
  {
    path: "/roster",
    name: "roster",
    component: RosterPage,
    beforeEnter: [middleware.isAuth],
  },
  {
    path: "/admin",
    component: AdminWrapper,
    beforeEnter: [() => middleware.isAdmin || middleware.isStoreKeeper],
    children: [
      { path: "", name: "admin.home", component: AdminHomePage },
      {
        path: "users",
        name: "admin.users.index",
        beforeEnter: [middleware.isAdmin],
        component: UsersIndex,
      },
      {
        path: "users/create",
        name: "admin.users.create",
        beforeEnter: [middleware.isAdmin],
        component: UsersCreate,
      },
      {
        path: "users/edit/:id",
        name: "admin.users.edit",
        beforeEnter: [middleware.isAdmin],
        component: UserEdit,
      },
      {
        path: "categories",
        name: "admin.categories.index",
        // beforeEnter: [middleware.isAdmin || middleware.isStoreKeeper],
        component: CategoriesIndex,
      },
      {
        path: "categories/create",
        name: "admin.categories.create",
        // beforeEnter: [middleware.isAdmin || middleware.isStoreKeeper],
        component: CategoriesCreate,
      },
      {
        path: "categories/edit/:id",
        name: "admin.categories.edit",
        // beforeEnter: [middleware.isAdmin || middleware.isStoreKeeper],
        component: CategoriesEdit,
      },
      {
        path: "stores",
        name: "admin.stores.index",
        // beforeEnter: [middleware.isAdmin || middleware.isStoreKeeper],
        component: StoresIndex,
      },
      {
        path: "stores/create",
        name: "admin.stores.create",
        // beforeEnter: [middleware.isAdmin || middleware.isStoreKeeper],
        component: StoresCreate,
      },
      {
        path: "stores/edit/:id",
        name: "admin.stores.edit",
        // beforeEnter: [middleware.isAdmin || middleware.isStoreKeeper],
        component: StoresEdit,
      },
      {
        path: "products",
        name: "admin.products.index",
        // beforeEnter: [middleware.isAdmin || middleware.isStoreKeeper],
        component: ProductsIndex,
      },
      {
        path: "products/create",
        name: "admin.products.create",
        // beforeEnter: [middleware.isAdmin || middleware.isStoreKeeper],
        component: ProductsCreate,
      },
      {
        path: "products/edit/:id",
        name: "admin.products.edit",
        // beforeEnter: [middleware.isAdmin || middleware.isStoreKeeper],
        component: ProductsEdit,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    beforeEnter: [middleware.isGust],
  },
  { path: '/500',name: "500", component: ServerErrorPage },
  { path: '/:pathMatch(.*)*',name: "404", component: NotFoundPage },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

export default router;
