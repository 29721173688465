<script setup>
import Axios from '@/axios';
// import swal from '@/swal';
import { computed, defineProps,ref } from 'vue';

const Props = defineProps({
    product: {
        required: true,
        type: Object
    },
    setImageModalUrl: {
        required: true,
        type: Function
    }
});
const actionLoading = ref(false)
const quantityOfCartons = computed(() => Math.floor(Props.product.quantity / Props.product.carton_contains));
const microQuantity = computed(() => Math.floor(Props.product.quantity % Props.product.carton_contains));

const quantityOfCartonsInList = computed(() => Math.floor(Props.product.inRoster.quantity / Props.product.carton_contains));
const microQuantityInList = computed(() => Math.floor(Props.product.inRoster.quantity % Props.product.carton_contains));

const setZeroQuantityAndDeleteFromRoster = async()=>{
try {
    actionLoading.value = true;
    await Axios.post(`/roster/set-zero/${Props.product.id}`);
    actionLoading.value = false;
} catch (error) {
    actionLoading.value = false;
    console.log(error);
}
}
const setZeroQuantityAndDoneExtract = async()=>{
try {
    actionLoading.value = true;
    await Axios.post(`/roster/extract-all/${Props.product.id}`);
    actionLoading.value = false;
} catch (error) {
    actionLoading.value = false;
    console.log(error);
}
}
const doneExtract = async()=>{
try {
    actionLoading.value = true;
    await Axios.post(`/roster/extract/${Props.product.id}`);
    actionLoading.value = false;
} catch (error) {
    actionLoading.value = false;
    console.log(error);
}
}

</script>

<template>
    <div class="basis-full lg:basis-1/2 p-2">
        <div class="p-2 rounded-md flex-flex-col relative" :class="[Props.product.inRoster && Props.product.inRoster.status == 1 ? 'bg-palette1-yellow-1 bg-opacity-30' : 'bg-white' ]">
            <div class="form-check absolute top-0 left-0">
                <input :checked="Props.product.inRoster.status" type="checkbox" :id="`product-${Props.product.id}`">
                <span class="custom-check"> </span>
            </div>
            <div class="flex flex-row items-center mb-2">
                <img @click="Props.setImageModalUrl(`${Props.product.image}&width=400&height=400`)"
                    :src="`${Props.product.image}&width=50&height=50`" :alt="Props.product.name" width="50" height="50" />
                <div class="flex flex-col mx-2 space-y-2 w-full">
                    <span class="text-gray-800 text-sm font-bold">{{ Props.product.name }}</span>
                    <div class="w-full flex flex-row justify-start">
                        <span class="basis-1/2 text-gray-400 font-bold text-xs">دسته بندی:<span class="text-red-500">{{
                            Props.product.category.name }}</span></span>
                        <span class="basis-1/2 text-gray-400 font-bold text-xs">انبار :<span class="text-red-500">{{
                            Props.product.store.name }}</span></span>
                    </div>

                    <div class="w-full flex flex-row justify-start">
                        <span class="basis-1/2 text-gray-400 font-bold text-xs">موجودی‌ انبار: <span
                                class="text-red-900 mx-1">{{
                                    quantityOfCartons }} </span> کارتن {{ microQuantity > 0 ? 'و' : '' }}<span
                                v-if="microQuantity > 0" class="text-red-900 mx-1"> {{
                                    microQuantity }}</span>{{ microQuantity > 0 ? 'عدد' : '' }}</span>
                        <span class="basis-1/2 text-gray-400 font-bold text-xs">ثبت شده در لیست: <span
                                class="text-red-900 mx-1">{{
                                    quantityOfCartonsInList }} </span> کارتن {{ microQuantityInList > 0 ? 'و' : '' }}<span
                                v-if="microQuantityInList > 0" class="text-red-900 mx-1"> {{
                                    microQuantityInList }}</span>{{ microQuantityInList > 0 ? 'عدد' : '' }}</span>
                    </div>
                    <div v-if="Props.product.inRoster.user" class="w-full flex flex-row justify-start">
                        <span class="basis-1/2 text-gray-400 font-bold text-xs">ثبت شده توسط :<span class="text-red-500">{{ Props.product.inRoster.user.name }}</span></span>
                        
                    </div>


                </div>
            </div>
            <div v-if="actionLoading" class="flex flex-row justify-center">
                <div class="loading-dots px-6 py-2">
                            <div class="loading-dot bg-green-600"></div>
                            <div class="loading-dot bg-green-600"></div>
                            <div class="loading-dot bg-green-600"></div>
                        </div>
            </div>
            <div v-else-if="Props.product.inRoster && Props.product.inRoster.status == 0" class="flex flex-row items-start">
                <button @click="setZeroQuantityAndDeleteFromRoster" class="mx-2 py-2 px-4 bg-palette1-red-2 rounded-md text-white text-sm">عدم موجودی</button>
                <button @click="setZeroQuantityAndDoneExtract" class="mx-2 py-2 px-4 bg-palette1-orange-3 rounded-md text-white text-sm">بارگیری کل موجودی</button>
                <button @click="doneExtract"
                    class="mx-2 py-2 px-4 bg-palette1-green-3 disabled:bg-palette1-green-1 disabled:text-gray-600 rounded-md text-white text-sm">بارگیری
                    شده</button>

            </div>
        </div>

    </div>
</template>