<script setup>
import { ref,computed } from "vue";
import swal from "@/swal";
import { useRouter } from "vue-router";
import SelectComponent from "@/components/inputs/SelectComponent";
import ImageInput from '@/components/inputs/ImageInput.vue'
import {createProductRequest} from '@/requests/productRequest'
import useStore from "@/store/stores";
import useCategories from "@/store/categories";

const router = useRouter();
const stores = computed(()=>useStore().stores)
const categories = computed(()=>useCategories().categories)
const productData = ref({
  name: null,
  barcode: null,
  store: null,
  category: null,
  carton: null,
  carton_contains: null,
  image: null
});

const validationErrors = ref({
  name: null,
  barcode: null,
  store: null,
  category: null,
  quantity: null,
  carton_contains: null,
  image: null
});


const submitCreateProduct = async () => {
  try {
    const response = await createProductRequest(productData.value);

    if (response.data.status == "success") {
      swal("success", `محصول ${productData.value.name} ایجاد شد.`);
      router.replace({ name: "admin.products.index" });
    }
  } catch (error) {
    if (error.response && error.response.status == 422) {
      validationErrors.value = error.response.data.errors;
    } else {
      console.log(error.response);
    }
  }
};

</script>

<template>
  <div class="min-w-full flex flex-col justify-start items-start bg-transparent rounded-lg p-4 mb-5">
    <div class="w-full flex flex-row justify-between items-center mb-5">
      <span class="font-bold text-gray-600">ایجاد محصول جدید</span>
      <RouterLink :to="{ name: 'admin.products.index' }"><i
          class="bi bi-arrow-left bg-gray-500 px-2 py-1 text-white text-xl text-center rounded-full"></i></RouterLink>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="productData.name" id="name" class="input-control"
          :class="{ 'outline-red-500': validationErrors.name }" type="text" placeholder=" " />
        <label for="name" class="label-control">نام محصول</label>
      </div>
      <span class="text-sm font-semibold text-gray-600 px-2 pt-1 block">فرمت مناسب : تست-مایع لباس-2500-آبی(شبنم)</span>
      <span v-if="validationErrors.name" class="invalid">{{
        validationErrors.name[0]
      }}</span>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="productData.barcode" id="barcode" class="input-control"
          :class="{ 'outline-red-500': validationErrors.barcode }" type="number" placeholder=" " />
        <label for="barcode" class="label-control">بارکد</label>
      </div>
      <span v-if="validationErrors.barcode" class="invalid">{{
        validationErrors.barcode[0]
      }}</span>
    </div>

    <SelectComponent :options="categories" :onChange="(select) => (productData.category = select)"
      :error="validationErrors.category" label='دسته بندی محصول را انتخاب کنید' />
    <SelectComponent :options="stores" :onChange="(select) => (productData.store = select)"
      :error="validationErrors.store" label='انبار قرارگیری محصول را انتخاب کنید' />

    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="productData.carton_contains" id="carton_contains" class="input-control"
          :class="{ 'outline-red-500': validationErrors.carton_contains }" type="number" placeholder=" " />
        <label for="carton_contains" class="label-control">تعداد در کارتن</label>
      </div>
      <span v-if="validationErrors.carton_contains" class="invalid">{{
        validationErrors.carton_contains[0]
      }}</span>
    </div>

    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="productData.quantity" id="quantity" class="input-control"
          :class="{ 'outline-red-500': validationErrors.quantity }" type="number" placeholder=" " />
        <label for="quantity" class="label-control">تعداد کل</label>
      </div>
      <span v-if="validationErrors.quantity" class="invalid">{{
        validationErrors.quantity[0]
      }}</span>
    </div>

    <div class="mb-4 w-full">
      <ImageInput :onChange="(event) => (productData.image = event.target.files[0])" :error="validationErrors.image"/>
    </div>

    <div class="flex flex-row w-full justify-end">
      <button @click="submitCreateProduct" class="px-4 py-2 bg-white rounded-md text-gray-500">
        ایجاد محصول
      </button>
    </div>
  </div>
</template>
