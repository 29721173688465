<script setup>
import { computed, ref } from "vue";
import useUser from "@/store/user";
import { logoutRequest } from "@/requests/authRequest";
import { useRouter } from "vue-router";

const userStore = useUser();
const router = useRouter();
const letterOfName = computed(() => {
  let name = userStore.user.name.split(" ", 2);
  if (name[1]) {
    return `${name[0].substring(0, 1).toUpperCase()}‌${name[1]
      .substring(0, 1)
      .toUpperCase()}`;
  } else {
    return `${name[0].substring(0, 1).toUpperCase()}`;
  }
});

// dropDown
const logoutLoading = ref(false);
const dropDown = ref(false);
const closeDropdown = () => {
  dropDown.value = false;
};

// method
const logout = async () => {
  if (userStore.user) {
    try {
      logoutLoading.value = true;
      await logoutRequest();
      userStore.clear();
      logoutLoading.value = false;
      closeDropdown();
      router.replace({ name: 'home' })
    } catch (error) {
      logoutLoading.value = false;
      console.log(error);
    }
  }
};
</script>

<template>
  <div class="py-2 px-4 bg-palette1-gray-4 flex flex-row justify-between items-center sticky top-0 z-[1005]">
    <img src="./../../assets/logo.png" width="60" height="100" alt="logo" />
    <div v-if="userStore.loading">
      <div class="flex flex-row items-center cursor-pointer">
        <span class="mx-2 px-8 py-2 bg-palette1-gray-2 rounded-md"></span>
        <div class="h-8 w-8 rounded-full bg-palette1-gray-2 text-center flex flex-col justify-center"></div>
      </div>
    </div>
    <div v-else-if="userStore.$state.user && userStore.$state.user != null" v-click-outside="closeDropdown"
      class="relative">
      <div @click="dropDown = !dropDown" class="flex flex-row items-center cursor-pointer">
        <span class="mx-2 font-bold text-palette1-gray-1">{{
          userStore.user.name
        }}</span>
        <div v-if="userStore.user.image == null"
          class="h-10 w-10 rounded-full bg-palette1-orange-1 text-center flex flex-col justify-center">
          {{ letterOfName }}
        </div>
        <img v-else class="rounded-full" :src="`${userStore.user.image}&width=50&height=50`" :alt="userStore.user.name" width="40" height="40">
      </div>
      <Transition enter-active-class="transition-all duration-300 origin-top-left"
        leave-active-class="transition-all duration-300 origin-top-left" enter-from-class="scale-y-0"
        enter-to-class="scale-y-100" leave-from-class="scale-y-100" leave-to-class="scale-y-0">
        <div v-show="dropDown"
          class="px-2 py-4 border-2 bg-white absolute w-72 max-w-[80vw] max-h-[80vh] overflow-y-auto overflow-x-hidden top-[40px] left-0 rounded-lg z-[1002]">
          <div class="bg-palette1-gray-1 p-2 rounded-lg flex items-end">
            <div v-if="userStore.user.image == null" class="h-12 w-12 rounded-full bg-palette1-orange-1 text-center flex flex-col justify-center">
              {{ letterOfName }}
            </div>
            <img v-else class="rounded-full" :src="`${userStore.user.image}&width=50&height=50`" :alt="userStore.user.name" width="50" height="50">
            <div class="flex flex-col ms-4">
              <span class="font-bold">{{ userStore.user.name }}</span>
              <span class="text-sm text-palette1-gray-4">فروشنده</span>
            </div>
          </div>
          <ul class="mt-4 font-thin">
            <li v-if="userStore.role == 'Super Admin' || userStore.role == 'Store Keeper'"
              class="bg-palette1-gray-1 p-2 rounded-md mb-2">
              <RouterLink :to="{ name: 'admin.home' }">
                <span>پنل ادمین</span>
              </RouterLink>
            </li>
            <li @click="logout" class="bg-palette1-red-1 p-2 rounded-md flex flex-row justify-between items-center">
              <span class="text-white">خروج</span>
              <div v-if="logoutLoading"
                class="h-4 w-4 border-2 border-transparent border-t-white animate-spin rounded-full"></div>
              <i v-else class="bi bi-box-arrow-left text-white mt-1"></i>
            </li>
          </ul>
        </div>
      </Transition>
    </div>

    <div v-else class="flex flex-row">
      <RouterLink :to="{ name: 'login' }"
        class="px-4 py-1 bg-palette1-orange-2 rounded-md text-white flex flex-row items-center justify-between">
        <span>ورود</span>
        <i class="bi bi-box-arrow-in-right text-xl ms-2"></i>
      </RouterLink>
    </div>
  </div>
</template>
