<script setup>
import { loginRequest } from "@/requests/authRequest";
import { ref } from "vue";
import useUser from "@/store/user";
import { useRouter } from "vue-router";

// store define
const userStore = useUser();
const router = useRouter();
const loading = ref(false);

// component data
const userData = ref({
  phone: null,
  password: null,
});

const validationErrors = ref({
  phone: null,
  password: null,
});

// component methods
const loginSubmit = async () => {
  try {
    loading.value = true;
    await loginRequest(userData.value);
    userStore.fetchUser();
    loading.value = false
    router.replace({ name: 'home' });
  } catch (error) {
    loading.value = false
    if (error.response && error.response.status == 422) {
      validationErrors.value = error.response.data.errors;
    } else {
      console.log(error.response);
    }
  }
};
</script>

<template>
  <div class="flex flex-row justify-center">
    <div class="bg-white bg-opacity-70 rounded-md flex flex-col items-center max-w-lg p-4 mb-12">
      <img src="../../assets/logo.png" width="400" alt="logo" />
      <div class="w-full mb-4">
        <div class="form-control bg-white flex items-center">
          <input v-model="userData.phone" id="phone" type="text" class="input-control rounded-l-none" placeholder=" " />
          <span class="p-2 bg-gray-500 text-white border-2 border-gray-500 rounded-md rounded-r-none">98+</span>
          <label for="phone" class="label-control">شماره همراه</label>
        </div>
        <span v-if="validationErrors.phone" class="invalid">{{
          validationErrors.phone[0]
        }}</span>
      </div>
      <div class="w-full mb-4">
        <div class="form-control bg-white">
          <input v-model="userData.password" id="password" type="password" class="input-control" placeholder=" " />
          <label for="password" class="label-control">رمز ورود</label>
        </div>
        <span v-if="validationErrors.password" class="invalid">{{
          validationErrors.password[0]
        }}</span>
      </div>
      <div v-if="loading" class="loading-dots px-6 py-4 w-full rounded-md bg-palette1-green-1">
        <div class="loading-dot bg-white"></div>
        <div class="loading-dot bg-white"></div>
        <div class="loading-dot bg-white"></div>
      </div>
      <button v-else @click="loginSubmit" class="w-full bg-palette1-green-1 rounded-md py-2 cursor-pointer text-white">
        ورود
      </button>
    </div>
  </div>
</template>
