<template>
  <HeaderComponent />
  <div class="py-12 container px-4">
    <RouterView />
  </div>
  <BottomLinks />
</template>

<script setup>
import HeaderComponent from "@/components/header/HeaderComponent.vue";
import BottomLinks from "./components/BottomLinks.vue";
import Axios from '@/axios';
import { useRouter } from "vue-router";
import useUser from '@/store/user'
import useStore from "./store/stores";
import useCategories from "./store/categories";
import echo from "./echo";



const router = useRouter();
const userStore = useUser();
const storeStore = useStore();
const categoriesStore = useCategories();

Axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response && error.response.status == 500) {
      router.push({name:'500'});
    }else if(error.response && error.response.status == 404){
      router.push({name:'404'});
    }
    return Promise.reject(error);
  }
);


const publicChannelListen = () => {
  echo.channel('categories').stopListening('.add').stopListening('.edit').stopListening('.delete')
  echo.channel('stores').stopListening('.add').stopListening('.edit').stopListening('.delete')

  echo.channel('categories').listen('.add', (e) => {
    categoriesStore.addCategory(e.category);
  });

  echo.channel('categories').listen('.edit', (e) => {
    categoriesStore.editCategory(e.category);
  });

  echo.channel('categories').listen('.delete', (e) => {
    categoriesStore.removeCategory(e.id);
  });

  echo.channel('stores').listen('.add', (e) => {
    storeStore.addStore(e.store);
  });

  echo.channel('stores').listen('.edit', (e) => {
    console.log('edit')
    storeStore.editStore(e.store);
  });

  echo.channel('stores').listen('.delete', (e) => {
    storeStore.removeStore(e.id);
  });
}

publicChannelListen();
userStore.fetchUser();
storeStore.fetchStores();
categoriesStore.fetchCategories();
</script>

<style></style>
