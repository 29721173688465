<script setup>
import { ref } from "vue";
import swal from "@/swal";
import { useRoute, useRouter } from "vue-router";
import { indexRequest } from "@/requests/roleRequest";
import SelectComponent from "@/components/inputs/SelectComponent";
import { getUser, updateRequest } from "@/requests/userRequest";

const route = useRoute();
const router = useRouter();
const userId = route.params.id;
const loading = ref(true);

const registerData = ref({
  name: null,
  phone: null,
  password: null,
  confirm_password: null,
  role: null,
});

const validationErrors = ref({
  name: null,
  phone: null,
  password: null,
  password_confirmation: null,
  role: null,
});

const roles = ref([]);

const fetchRoles = async () => {
  let response = await indexRequest();
  roles.value = response.data.data.roles;
};

const getUserData = async () => {
  try {
    let response = await getUser(userId);
    registerData.value.name = response.data.data.user.name;
    registerData.value.phone = response.data.data.user.phone;
    registerData.value.role = response.data.data.user.roles[0].id;
  } catch (error) {
    console.log(error);
  }
};

const updateUser = async () => {
  try {
    const response = await updateRequest(userId, {
      name:registerData.value.name ,
      phone:registerData.value.phone ,
      role:registerData.value.role ,
      password:registerData.value.password.length ? registerData.value.password : null,
      password_confirmation : registerData.value.password.length ? registerData.value.password_confirmation : null
    });

    if (response.data.status == "success") {
      swal("success", `کاربر ${registerData.value.name} ویرایش شد.`);
      router.replace({ name: "admin.users.index" });
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status == 422) {
      validationErrors.value = error.response.data.errors;
    } else {
      console.log(error.response);
    }
  }
};

const fetchData = async () => {
  await fetchRoles();
  await getUserData();
  loading.value = false
};

fetchData();

</script>

<template>
  <div class="min-w-full flex flex-col justify-start items-start bg-transparent rounded-lg p-4 mb-5">
    <div class="w-full flex flex-row justify-between items-center mb-5">
      <span class="font-bold text-gray-600">ویرایش کاربر</span>
      <RouterLink :to="{ name: 'admin.users.index' }"><i
          class="bi bi-arrow-left bg-gray-500 px-2 py-1 text-white text-xl text-center rounded-full"></i></RouterLink>
    </div>
  </div>
  <div v-if="loading" class=" animate-spin absolute w-8 h-8 !border-4  !border-b-blue-500 rounded-full top-1/2 right-1/2">
  </div>
  <div v-else>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="registerData.name" id="name" class="input-control"
          :class="{ 'outline-red-500': validationErrors.name }" type="text" placeholder=" " />
        <label for="name" class="label-control">نام کاربری</label>
      </div>
      <span v-if="validationErrors.name" class="invalid">{{
        validationErrors.name[0]
      }}</span>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="registerData.phone" id="phone" class="input-control"
          :class="{ 'outline-red-500': validationErrors.phone }" type="text" placeholder=" " />
        <label for="phone" class="label-control">شماره همراه</label>
      </div>
      <span v-if="validationErrors.phone" class="invalid">{{
        validationErrors.phone[0]
      }}</span>
    </div>

    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="registerData.password" id="password" class="input-control"
          :class="{ 'outline-red-500': validationErrors.password }" type="password" placeholder=" " />
        <label for="password" class="label-control">رمز عبور</label>
      </div>
      <span v-if="validationErrors.password" class="invalid">{{
        validationErrors.password[0]
      }}</span>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="registerData.password_confirmation" id="password_confirmation" class="input-control"
          :class="{ 'outline-red-500': validationErrors.password_confirmation }" type="password" placeholder=" " />
        <label for="password_confirmation" class="label-control">تکرار رمز عبور</label>
      </div>
      <span v-if="validationErrors.password_confirmation" class="invalid">{{
        validationErrors.password_confirmation[0]
      }}</span>
    </div>
    <SelectComponent :defaultSelect="registerData.role" :options="roles"
      :onChange="(select) => (registerData.role = select)" :error="validationErrors.role"
      label="نوع کاربری رو انتخاب کنید" />

    <div class="flex flex-row w-full justify-end">
      <button class="px-4 py-2 bg-white rounded-md text-gray-500" @click="updateUser">
        ویرایش کاربر
      </button>
    </div>
  </div>

  <div>


  </div>
</template>
