<script setup>
import { ref } from 'vue';
import swal from '@/swal';
import { useRouter } from 'vue-router';
import { createStore } from '@/requests/storeRequest';

const router = useRouter();
const storeData = ref({
  name: null,
});

const storeValidation = ref({
  name: null,
});

const submitCreateStore = async () => {
  try {
    let response = await createStore(storeData.value);

    if (response.data.status == "success") {
      swal("success", `انبار ${storeData.value.name} ایجاد شد.`);
      router.replace({ name: "admin.stores.index" });
    }
  } catch (error) {
    if (error.response && error.response.status == 422) {
      storeValidation.value = error.response.data.errors;
    } else {
      console.log(error.response);
    }
  }

}

</script>

<template>
  <div class="min-w-full flex flex-col justify-start items-start bg-transparent rounded-lg p-4 mb-5">
    <div class="w-full flex flex-row justify-between items-center mb-5">
      <span class="font-bold text-gray-600">ایجاد انبار جدید</span>
      <RouterLink :to="{ name: 'admin.stores.index' }"><i
          class="bi bi-arrow-left bg-gray-500 px-2 py-1 text-white text-xl text-center rounded-full"></i></RouterLink>
    </div>
    <div class="mb-4 w-full">
      <div class="form-control bg-white">
        <input v-model="storeData.name" id="name" class="input-control"
          :class="{ 'outline-red-500': storeValidation.name }" type="text" placeholder=" " />
        <label for="name" class="label-control">نام انبار</label>
      </div>
      <span v-if="storeValidation.name" class="invalid">{{
        storeValidation.name[0]
      }}</span>
    </div>

    <div class="flex flex-row w-full justify-end">
      <button @click="submitCreateStore" class="px-4 py-2 bg-white rounded-md text-gray-500">
        ایجاد انبار
      </button>
    </div>
  </div>
</template>
