import {
  indexRequest,
} from "@/requests/storeRequest";
import { defineStore } from "pinia";

const useStore = defineStore("store", {
  state: () => {
    return {
      stores: null,
    };
  },
  actions: {
    async fetchStores() {
      let response = await indexRequest();
      this.stores = response.data.data.stores;
    },
    removeStore(id) {
      this.stores = this.stores.filter(store=>store.id != id)
    },
    editStore(subStore){
      let index = this.stores.findIndex(store =>store.id == subStore.id );
      this.stores[index] = subStore;
    },
    addStore(store){
      this.stores = [...this.stores , store]
    }
  },
});

export default useStore;
